/* eslint-disable @typescript-eslint/naming-convention */

import React, { useEffect } from 'react';

import { useAtom } from 'jotai';
import jwt_decode from 'jwt-decode';
import { useLocalStorage } from 'usehooks-ts';

import { Value } from '@/lib/constants';
import {
  AuthTokenAtom,
  UserDataAtom,
} from '@/modules/core/store/grpc-free-store';

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [, setAuthToken] = useAtom(AuthTokenAtom);
  const [, setUserData] = useAtom(UserDataAtom);
  const [, setExpires_in] = useLocalStorage<Value<number>>('expires_in', null);
  const [, setRefresh_expires_in] = useLocalStorage<Value<number>>(
    'refresh_expires_in',
    null
  );

  const logout = () => {
    setUserData({});
    setExpires_in(null);
    setAuthToken('');
    setRefresh_expires_in(null);
    window.location.href = '/login';
  };

  const checkTokenExpiration = () => {
    const token = localStorage.getItem('auth_token');
    if (token) {
      const decodedToken: any = jwt_decode(
        localStorage.getItem('auth_token') || ''
      );
      const expirationTime = decodedToken.exp * 1000;
      const currentTime = Date.now();

      if (expirationTime && currentTime > expirationTime) {
        logout();
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(checkTokenExpiration, 30000);
    return () => clearInterval(interval);
  }, []);

  return <>{children}</>;
};

export default AuthProvider;
