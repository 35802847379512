import React, { ComponentType, ReactElement } from 'react';

import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useAtom } from 'jotai';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { appWithTranslation } from 'next-i18next';
import { useEffectOnce } from 'usehooks-ts';

import useAuth from '@/lib/hooks/useAuth';
import useGoogleAdsPageTransition from '@/lib/hooks/useGoogleAdsPageTransition';
// eslint-disable-next-line import/order
import { usePreserveScroll } from '@/lib/hooks/usePreserveScroll';
import '../styles/global.css';

import 'dayjs/locale/fr';
// import 'dayjs/locale/ar';

import {
  pageLoading,
  PageLoadingAction,
  LoadHeavyContentAtom,
  stickyBannerAtom,
} from '@/lib/store';
import AuthProvider from '@/modules/auth/providers/AuthProvider';
import NextBreadcrumb from '@/modules/core/components/NextBreadcrumb';
import { NEXT_API_URL } from 'environment';

// Extending the NextPage type with getLayout
export type NextPageWithLayout<P = {}> = NextPage<P> & {
  getLayout?: (page: ReactElement, props: P) => ReactElement;
  // getLayout?: (page: ReactElement) => ReactNode;
  layout?: ComponentType;
  authenticate?: boolean;
};

// Extending AppProps type with getLayout
type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const GoogleAdsLoader = () => {
  const [loadHeavyContent] = useAtom(LoadHeavyContentAtom);

  if (typeof window !== 'undefined') {
    window.googletag = window.googletag || { cmd: [] };
  }
  useGoogleAdsPageTransition();

  return loadHeavyContent ? (
    <>
      <Script src="https://securepubads.g.doubleclick.net/tag/js/gpt.js" />
    </>
  ) : null;
};

if (typeof window !== 'undefined') {
  window.googletag = window.googletag || { cmd: [] };
}

// Main _app.tsx code
const MyApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  // If the page has a `getLayout` function use it, otherwise use a function that returns the same input page.

  // useEffect(() => {
  //   if ('serviceWorker' in navigator) {
  //     window.addEventListener('load', () => {
  //       navigator.serviceWorker.register('/firebase-messaging-sw.js')
  //         .then(registration => {
  //           console.log('Service worker registered:', registration);
  //         })
  //         .catch(error => {
  //           console.error('Service worker registration failed:', error);
  //         });
  //     });
  //   }
  // }, []);

  const getLayout = Component.getLayout ?? ((page) => page);

  usePreserveScroll();
  const [, setIsLoading] = useAtom(pageLoading);
  // to handle user data
  const { logout, populateAtomWithUserData } = useAuth();
  const [, setStickyBanner] = useAtom(stickyBannerAtom);

  useEffectOnce(() => {
    fetch(`${NEXT_API_URL}/settings`)
      .then((res) => res.json())
      .then((settings) => {
        if (settings?.showStickyBanner) setStickyBanner(true);
      });
  });
  useEffectOnce(() => {
    populateAtomWithUserData();
    setTimeout(() => setIsLoading(PageLoadingAction.Stop), 2000);
    if (localStorage.getItem('newToken') !== 'true') {
      logout();
    }
  });

  const router = useRouter();
  dayjs.locale(router.locale);
  dayjs.extend(relativeTime);
  dayjs.extend(LocalizedFormat);

  return getLayout(
    <>
      {' '}
      <NextBreadcrumb
        homeElement={
          <div className="text-gray-600 flex items-center gap-x-2">
            <svg
              className=" w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="#718096"
              viewBox="0 0 20 20"
            >
              <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
            </svg>
            Accueil
          </div>
        }
        separator={
          <div className="flex items-center">
            <svg
              className="w-2 h-2 m-1 text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 9 4-4-4-4"
              />
            </svg>
          </div>
        }
        activeClasses=""
        containerClasses=" hidden md:flex  items-center flex-wrap   py-1   "
        listClasses=" p-2 my-1 text-xs text-gray-600"
        capitalizeLinks
      />
      <AuthProvider>
        <Component {...pageProps} />
      </AuthProvider>
      <Script src="/rudderstack.js" strategy="lazyOnload" />
      <GoogleAdsLoader />
    </>,
    pageProps
  );
};

export default appWithTranslation(MyApp);
